import React, { useEffect, useState } from 'react'
import * as utils from '../common/client-utils-commonjs'
import { T } from '../common/translate'
import { useAxiosGet } from '../hooks'
import { getInitialStatus, toggleCollapseTableCookie } from './collapsable-table'
import { GradingProgress } from '@digabi/grading-ui/lib/held-exams/grading-progress'

type Question = {
  answerCount: number
  correctAnswers: any[]
  displayNumber: string
  gradedAnswerCount: number
  id: number
  maxScore: number
  type: string
  gradingCompleted?: boolean
  gradingProgress?: number
}

type ProductiveExam = {
  date: string
  eventDateStr?: string
  examCode: string
  title: string
  questions: Question[]
}

export const ProductiveCensoring = ({ divisionId }: { divisionId: string }) => {
  const [productiveExams, setProductiveExams] = useState<ProductiveExam[]>([])
  const [get] = useAxiosGet()

  useEffect(() => {
    void (async () => {
      const data = await get<ProductiveExam[]>(`/censor/productive-grading/division/${divisionId}/questions`)
      if (data) {
        setProductiveExams(formatProductiveExams(data))
      }
    })()
  }, [divisionId])

  const { gradingProgress } = progress(
    productiveExams
      .map(exam => exam.questions)
      .flat()
      .reduce((acc, curr) => acc + curr.answerCount, 0),
    productiveExams
      .map(exam => exam.questions)
      .flat()
      .reduce((acc, curr) => acc + curr.gradedAnswerCount, 0)
  )

  return (
    productiveExams.length > 0 && (
      <div id="productive-censoring" className="exam-table-shadow exam-table-row productive-censoring-tables">
        <table className="is_censor held-exams-table">
          <thead>
            <tr className="table-section-header">
              <th colSpan={2}>
                <span>
                  <T>sa.productive.navigation_header</T>
                </span>
                <span className="total-progress">
                  <span className="separate">&mdash;</span>
                  <span>
                    <T>sa.held_graded</T>
                  </span>
                  <GradingProgress gradingProgress={gradingProgress} />
                </span>
              </th>
            </tr>
          </thead>
        </table>
        {productiveExams.map((exam, index) => (
          <ProductiveExam key={index} {...exam} />
        ))}
      </div>
    )
  )
}

const ProductiveExam = ({ title, eventDateStr, questions, examCode }: ProductiveExam) => {
  const collapsableId = `productive-grading-${examCode}`
  const [collapsed, setCollapsed] = useState(getInitialStatus(collapsableId))

  const toggleCollapsed = (e: React.MouseEvent<HTMLTableRowElement>) => {
    toggleCollapseTableCookie(collapsableId, !collapsed)
    setCollapsed(!collapsed)
  }

  return (
    <table
      className={`is_censor held-exams-table ${collapsed ? 'is_collapsed' : 'is_expanded'}`}
      data-collapsable-id={collapsableId}>
      <thead>
        <tr className="table-section-header select-exam" onClick={toggleCollapsed}>
          <th colSpan={4}>
            <a className="js-exam-title collapse">
              <i className="fa fa-angle-down for_collapsed"></i>
              <i className="fa fa-angle-up for_expanded"></i>
              <span className="exam-title-collapse-link-text">{`${title} ${eventDateStr!} (${questions.length})`}</span>
            </a>
          </th>
        </tr>
        <tr className="for_expanded">
          <th className="header-productive-question">
            <T>sa.productive.question</T>
          </th>
          <th className="header-productive-answer-count">
            <T>sa.productive.answer_count</T>
          </th>
          <th className="header-productive-grading-progress">
            <T>sa.productive.grading_progress</T>
          </th>
          <th className="header-link-to-productive-grading"></th>
        </tr>
      </thead>
      <tbody className="for_expanded">
        {questions.map(({ displayNumber, answerCount, gradingCompleted, gradingProgress, id }) => (
          <tr key={id} className="js-productive-row">
            <td>{displayNumber}</td>
            <td>{answerCount}</td>
            <td className="js-productive-question-progress productive-progress">
              <GradingProgress gradingCompleted={gradingCompleted} gradingProgress={gradingProgress} />
            </td>
            <td>
              <a className="link-to-grades button" href={`/censor/productivegrading/${examCode}/question/${id}`}>
                <T>sa.held_exam_grade</T>
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const formatProductiveExams = (productiveGrading: ProductiveExam[]) =>
  productiveGrading.map(exam => ({
    ...exam,
    eventDateStr: utils.finnishDateString(new Date(exam.date)),
    questions: exam.questions.map(question => ({
      ...question,
      ...progress(question.answerCount, question.gradedAnswerCount)
    }))
  }))

const progress = (answerCount: number, gradedAnswerCount: number) => {
  const gradingCompleted = gradedAnswerCount === answerCount
  return {
    gradingCompleted,
    gradingProgress: Math.floor(100 * (gradingCompleted ? 1 : gradedAnswerCount / answerCount))
  }
}
