import React, { useContext, useState } from 'react'
import { T, translate, useLanguage } from './common/translate'
import axios from 'axios'
import { UserDataContext } from './app'
import { FloatingErrorContext } from './common/floating-error'

export const EulaContainer = ({ close, role }: { close: () => void; role: string }) => {
  const { user } = useContext(UserDataContext)
  const setCurrentError = useContext(FloatingErrorContext)
  function accept(eulaRole: string) {
    axios
      .post('/kurko-api/user/acceptEula', { role: eulaRole })
      .then(() => {
        user?.acceptedEulaRoles.push(role)
        close()
        return
      })
      .catch(() => setCurrentError('sa.errors.load_error'))
  }
  return user?.acceptedEulaRoles.includes(role) || user?.details.impersonation ? (
    <CurrentEula closeEula={close} role={role} />
  ) : (
    <Eula acceptEula={accept} role={role} />
  )
}

const Eula = ({ acceptEula, role }: { acceptEula: (eulaRole: string) => void; role: string }) => {
  const [checked, setChecked] = useState(false)
  useLanguage()
  return (
    <div id="eula-container">
      <div id="eula-dialog-base">
        <div dangerouslySetInnerHTML={{ __html: translate(`sa.eula.${role}.terms`) }} />
        <div id="eula-agreement-container">
          <div className="eula-checkbox-container">
            <input type="checkbox" id="eula-agreement-checkbox" checked={checked} onChange={() => {}} />
            <label htmlFor="eula-agreement-checkbox" onClick={() => setChecked(checked => !checked)}>
              <span className="eula-custom-checkbox" />
              <div dangerouslySetInnerHTML={{ __html: translate(`sa.eula.${role}.agreement`) }} />
            </label>
          </div>
          <button id="eula-agree-button" disabled={!checked} onClick={() => acceptEula(role)}>
            <T>{`sa.eula.${role}.agree`}</T>
          </button>
        </div>
      </div>
    </div>
  )
}

const CurrentEula = ({ closeEula, role }: { closeEula: () => void; role: string }) => {
  useLanguage()

  return (
    <div id="eula-container">
      <div id="eula-dialog-base">
        <div dangerouslySetInnerHTML={{ __html: translate(`sa.eula.${role}.terms`) }} />
        <div id="eula-display-container">
          <div dangerouslySetInnerHTML={{ __html: translate('sa.eula.updated') }} />
          <button id="eula-close-dialog-button" onClick={closeEula}>
            <T>sa.eula.return</T>
          </button>
        </div>
      </div>
    </div>
  )
}
