import React, { useState, useEffect } from 'react'
import axios, { isAxiosError } from 'axios'
import { UserData } from './common/types'
import { LoginPage } from './login-page'
import { NoAccess } from './no-access'
import { TabManager } from './common/tab-manager'
import { PageBanner } from './page-banner'

// This also doubles as priority order for the views
const ALLOWED_ROLES = ['CENSOR', 'PRINCIPAL', 'ASSISTANT', 'STUDENTCOUNSELLOR', 'GRADING_TEACHER']

function hasAllowedRole(userData: UserData) {
  return userData.roles?.some(role => ALLOWED_ROLES.includes(role.roleType))
}

function userHasNoRoles() {
  return window.location.search.includes('userhasnoroles')
}

export const UserDataContext = React.createContext<UserData>({} as UserData)

export const App = () => {
  const [userData, setUserData] = useState<UserData | undefined>()
  const [noAccess, setNoAccess] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get<UserData>('/kurko-api/user', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      })
      .then(res => {
        if (!res.data.roles || !hasAllowedRole(res.data)) setNoAccess(true)
        setUserData(res.data)
        return
      })
      .catch(error => {
        if (isAxiosError(error)) {
          if (error.response) {
            const response = error.response
            setUserData(response.data as UserData)
            if (response.status === 401 && userHasNoRoles()) setNoAccess(true)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <UserDataContext.Provider value={userData!}>
      <div id="pagebanner">
        <PageBanner userData={userData} />
      </div>
      <div id="page-content" className="content">
        {noAccess ? <NoAccess /> : loading ? <></> : userData?.roles && userData?.user ? <TabManager /> : <LoginPage />}
      </div>
      <div id="footer" className="footer" />
    </UserDataContext.Provider>
  )
}
