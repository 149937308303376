import * as L from 'partial.lenses'
import * as R from 'ramda'

export const prepareForRender = R.pipe(
  L.transform([
    L.elems,
    L.seq(
      L.branch({
        ophIdNumber: [L.when(R.is(String)), L.modifyOp(R.replace(/^.*\./, ''))],
        examinationType: L.modifyOp(R.concat('registration.examination_type_names.')),
        studyType: L.modifyOp(R.concat('registration.study_type_names.')),
        exams: L.modifyOp(R.sortBy(e => !e.mandatory)),
        incompleteExaminationReasons: [
          L.when(R.is(Array)),
          L.modifyOp(R.map(R.pipe(R.concat('examination_phase.invalid_graduation_status.'), R.replace(/-/g, '_'))))
        ]
      }),
      L.choose(({ firstnames, lastname }) => {
        if (R.is(String, firstnames) && R.is(String, lastname)) {
          return ['firstname', L.setOp(firstnames), 'lastname', L.setOp(lastname)]
        } else {
          return ['unknownName', L.setOp(true)]
        }
      })
    )
  ]),
  R.sortBy(R.prop('studentNumber'))
)

export const prepareExaminationPhaseTabForRender = L.transform([
  L.elems,
  L.seq(
    L.branch({
      incompleteExaminationReasons: [
        L.when(R.is(Array)),
        L.modifyOp(R.map(R.pipe(R.concat('examination_phase.invalid_graduation_status.'), R.replace(/-/g, '_'))))
      ]
    }),
    L.choose(({ firstnames, lastname }) => {
      if (R.is(String, firstnames) && R.is(String, lastname)) {
        return ['firstname', L.setOp(firstnames), 'lastname', L.setOp(lastname)]
      } else {
        return ['unknownName', L.setOp(true)]
      }
    })
  )
])
