import React, { useEffect, useState } from 'react'
import * as L from 'partial.lenses'
import { T } from '../common/translate'
import * as studentsUtil from '../registration/students-util'
import { Student } from '../registration/registration'
import { ExaminationHistory } from './exam-history-row'
import { GraduationNoticeModal } from './graduation-notice-modal'
import uri from 'uri-tag'
import moment from 'moment'
import { TabProps } from '../common/types'
import { useAxiosGet } from '../hooks'
import { LoadingSpinner } from '../common/loading-spinner'

export function ExaminationPhaseTable({ roleType, scopeId }: TabProps) {
  const [students, setStudents] = useState<Student[]>([])
  const [get, loading] = useAxiosGet()
  useEffect(() => {
    void (async () => {
      const data = await get<{ students: Student[] }>(uri`/student/${scopeId}/students`, 500)
      if (data) {
        setStudents(data.students)
      }
    })()
  }, [scopeId])

  const formattedStudents = studentsUtil.prepareExaminationPhaseTabForRender(students) as Student[]
  const hasUnknownNames = L.or(L.flat('unknownName'))(formattedStudents)

  const studentElements = formattedStudents.map(student => (
    <StudentRow student={student} isPrincipal={roleType === 'PRINCIPAL'} schoolId={scopeId} key={student.ssn} />
  ))

  if (loading) {
    return <LoadingSpinner loading={loading} />
  }

  return (
    <div id="examination-phase-tab" className="tab">
      <h1>
        <T>examination_phase.title</T>
      </h1>
      <table className="examination-phase-table">
        <thead>
          <tr>
            <th className="name-col">
              <div className="lastname">
                <T>registration.lastname</T>
              </div>
              <T>registration.firstname</T>
            </th>
            <th className="ssn-col">
              <div>
                <T>registration.ssn</T>
              </div>
            </th>
            <th className="restart-col">
              <T>registration.restart</T>
            </th>
            <th className="right-to-study-col">
              <T>examination_phase.study_status</T>
            </th>
            <th className="graduation-notice">
              <T>examination_phase.graduation_notice</T>
            </th>
            <th className="has-necessary-exams-col">
              <T>examination_phase.has_necessary_exams</T>
            </th>
            <th className="show-examination-history">
              <T>examination_phase.examination_history</T>
            </th>
          </tr>
        </thead>
        <tbody>{studentElements}</tbody>
      </table>
      {hasUnknownNames && (
        <div className="info_container">
          <span>*</span>
          <span className="unknown_student_name_info">
            <T>registration.unknown_name_info_accepted</T>
          </span>
        </div>
      )}
    </div>
  )
}

export function StudentRow(props: { student: Student; isPrincipal: boolean; schoolId: string }) {
  const [expandedRows, setExpandedRows] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [principalNoticeDate, setPrincipalNoticeDate] = useState(props.student.principalNoticeDate)
  const {
    unknownName,
    isLaw2022Student,
    lastname,
    firstname,
    isRestarter,
    ssn,
    incompleteExaminationReasons,
    studentUuid,
    ophStudiesCompletedDate
  } = props.student

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const isRowExpanded = expandedRows.includes(studentUuid)

  const handleStudentDetailsRow = () => {
    if (isRowExpanded) {
      const index = expandedRows.indexOf(studentUuid)
      setExpandedRows([...expandedRows.slice(0, index), ...expandedRows.slice(index + 1)])
      return
    }

    setExpandedRows(prev => [...prev, studentUuid])
  }

  const handleCloseModal = (date: undefined | string = undefined) => {
    if (!date) {
      setIsModalOpen(false)
      return
    }

    setPrincipalNoticeDate(date)
    setIsModalOpen(false)
  }

  function getGraduationNoticeColumn() {
    if (principalNoticeDate) {
      return (
        <>
          <T>examination_phase.declared_as_graduated</T> {moment(principalNoticeDate).format('DD.MM.YYYY')}
        </>
      )
    }
    if (ophStudiesCompletedDate) {
      return <span>-</span>
    }
    if (!props.isPrincipal) {
      return <span>-</span>
    }
    return (
      <>
        <GraduationNoticeModal
          isOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          student={props.student}
          schoolId={props.schoolId}
        />
        <button className="graduation-notice-btn" onClick={() => handleOpenModal()}>
          <T>examination_phase.graduation_notice_button</T>
        </button>
      </>
    )
  }

  return (
    <>
      <tr>
        <td className={`name-col ${unknownName ? 'unknown' : ''}`}>
          {!isLaw2022Student && <div className="pre2022student" />}
          {unknownName ? (
            <T>registration.unknown_name</T>
          ) : (
            <>
              <div className="lastname">{lastname},</div>
              <div className="firstname">{firstname}</div>
            </>
          )}
        </td>
        <td className="ssn-col">{ssn}</td>
        <td className="restart-col">{isRestarter && '✔'}</td>
        <td className="student-status-col">
          {principalNoticeDate && !ophStudiesCompletedDate ? (
            <></>
          ) : ophStudiesCompletedDate ? (
            <T>examination_phase.graduated</T>
          ) : (
            <T>examination_phase.not_completed</T>
          )}
        </td>
        <td className="graduation-notice-col">{getGraduationNoticeColumn()}</td>
        {incompleteExaminationReasons?.length ? (
          <td className="has-necessary-exams-col has-necessary-exams-col-incomplete">
            <ul>
              {incompleteExaminationReasons.map((reason: string) => (
                <li key={reason}>
                  <T>{reason}</T>
                </li>
              ))}
            </ul>
          </td>
        ) : incompleteExaminationReasons === null ? (
          <td className="has-necessary-exams-col">
            <ul>
              <li>-</li>
            </ul>
          </td>
        ) : (
          <td className="has-necessary-exams-col has-necessary-exams-col-complete">
            <ul>
              <li />
            </ul>
          </td>
        )}
        <td className="show-examination-history" id={`expand-${studentUuid}`}>
          <ExpandStudentDetails toggleRow={handleStudentDetailsRow} expanded={isRowExpanded} />
        </td>
      </tr>
      <tr id={`row-${studentUuid}`}>
        <ExaminationHistory expanded={isRowExpanded} studentUuid={studentUuid} isLaw2022Student={isLaw2022Student} />
      </tr>
    </>
  )
}

interface ExpandableRowProps {
  expanded: boolean
  toggleRow: () => void
}

function ExpandStudentDetails(props: ExpandableRowProps) {
  return (
    <a onClick={props.toggleRow} className="expand-link">
      <i className={props.expanded ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
    </a>
  )
}
