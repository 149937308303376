import React from 'react'
import { T } from '../common/translate'

import { hasUnknownNames, Student } from './registration'
import { RegistrationErrorTable, RegistrationWarningTable } from './registration-errors'
import moment from 'moment'

interface DryRunStudentsProps {
  students: Student[]
  timestamp: string | null
}

export function DryRunStudents(props: DryRunStudentsProps) {
  const { students, timestamp } = props

  if (!students || !students.length) {
    return null
  }

  const totalStudents = students.length
  const studentsWithErrors = students.filter(student => student.errors && student.errors.length).length
  const studentsWithWarnings = students.filter(student => student.warnings && student.warnings.length).length
  const studentsWithoutErrors = totalStudents - studentsWithErrors - studentsWithWarnings

  const noErrorsOrWarnings = totalStudents === studentsWithoutErrors

  return (
    <div>
      <p>
        <span className="dry-run-title">
          <T>registration.dry_run_results_without_errors</T>&nbsp;{studentsWithoutErrors}/{totalStudents}
          {noErrorsOrWarnings && <span className="dry-run-no-errors" />}
        </span>
        <span className="dry-run-uploaded-info">
          (<T>registration.dry_run_uploaded</T> {moment(timestamp).format('DD.MM.YYYY HH:mm')})
        </span>
      </p>
      {noErrorsOrWarnings && (
        <p>
          <T>registration.dry_run_reminder</T>
        </p>
      )}

      {!noErrorsOrWarnings && (
        <div>
          <span className="dry-run-title">
            <T>registration.dry_run_errors_and_warnings</T>
          </span>
          <RegistrationWarningTable
            students={students}
            hasUnknownNames={hasUnknownNames(students)}
            showMessage={false}
          />
          <RegistrationErrorTable students={students} hasUnknownNames={hasUnknownNames(students)} dryRun={true} />
        </div>
      )}
    </div>
  )
}
