import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { UnifiedRole } from './common/types'
import { T } from './common/translate'

const ROLE_COOKIE_NAME = 'selected-role'

function getClassNames(active: UnifiedRole, role: UnifiedRole) {
  return active.roleType === role.roleType && active.scopeId === role.scopeId ? 'role active' : 'role'
}

export const RoleDisplay = ({
  activeRole,
  unifiedRoles,
  setActiveRole
}: {
  activeRole: UnifiedRole
  unifiedRoles: UnifiedRole[]
  setActiveRole: (role: UnifiedRole) => void
}) => {
  const [switchingRole, setSwitchingRole] = useState(false)

  const handleRoleChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const role = JSON.parse(event.currentTarget.dataset.role || '{}') as UnifiedRole
    setActiveRole(role)
    setSwitchingRole(false)
    Cookies.set(ROLE_COOKIE_NAME, JSON.stringify(role))
  }
  return (
    <>
      <h1 className="active-role-description" data-school-id={activeRole.scopeId}>
        <span className="scope-name">{activeRole.scopeName}</span>,{' '}
        <span className="role">
          <T>{`sa.${activeRole.roleType}_role`}</T>
        </span>
      </h1>
      {unifiedRoles.length > 1 && (
        <div className="role-change">
          <button id="show-role-selection" onClick={() => setSwitchingRole(true)}>
            <T>sa.change</T>
          </button>

          {switchingRole && (
            <>
              <div className="hide-role-selection" onClick={() => setSwitchingRole(false)}></div>
              <div id="role-selection">
                {unifiedRoles.map((role, index) => (
                  <div
                    key={index}
                    className={getClassNames(activeRole, role)}
                    data-role={JSON.stringify(role)}
                    onClick={handleRoleChange}>
                    <span className="type">
                      <T>{`sa.${role.roleType}_role`}</T>
                    </span>
                    , {role.scopeName}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
