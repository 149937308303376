import React, { useEffect, useState } from 'react'
import { T } from '../common/translate'
import { client, Student } from '../registration/registration'
import uri from 'uri-tag'
import i18next from 'i18next'
import moment from 'moment'

export function GraduationNoticeModal(props: {
  isOpen: boolean
  student: Student
  schoolId: string
  handleCloseModal: (date?: string) => void
}) {
  const [isSendNoticeButtonDisabled, setIsSendNoticeButtonDisabled] = useState(true)
  const [graduationNoticeFailed, setGraduationNoticeFailed] = useState(false)
  const [dateFieldState, setDateFieldState] = useState({
    touched: false,
    isValid: false,
    className: 'modal-text-input'
  })
  const [noticeDetails, setNoticeDetails] = useState({
    reason: '',
    educationType: 'yo',
    timeStamp: '',
    issuer: '',
    isConfirmed: false
  })

  useEffect(() => {
    areRequiredFieldsFilled()
  }, [noticeDetails, dateFieldState])

  const handleConfirm = async () => {
    try {
      await client.post(uri`/student/${props.student.studentUuid}/principal-notice`, {
        reason: noticeDetails.reason,
        educationType: noticeDetails.educationType,
        studiesCompletedDate: noticeDetails.timeStamp,
        issuer: noticeDetails.issuer,
        schoolId: props.schoolId
      })
      props.handleCloseModal(moment().format())
    } catch {
      setGraduationNoticeFailed(true)
    }
  }

  const validateDate = (input: string) => {
    if (!dateFieldState.touched && !input) {
      return
    }

    const date = moment(input, ['D.M.YYYY', 'D.MM.YYYY', 'DD.M.YYYY', 'DD.MM.YYYY'], true)

    if (date.isValid()) {
      setDateFieldState({ touched: true, isValid: true, className: 'modal-text-input' })
      return
    }
    setDateFieldState({ touched: true, isValid: false, className: 'invalid-text-input' })
  }

  function areRequiredFieldsFilled() {
    if (!dateFieldState.isValid) {
      setIsSendNoticeButtonDisabled(true)
      return
    }

    const detailsAreNotFilled = Object.keys(noticeDetails).some(
      key => !noticeDetails[key as 'reason' | 'educationType' | 'timeStamp' | 'issuer' | 'isConfirmed']
    )

    if (detailsAreNotFilled) {
      setIsSendNoticeButtonDisabled(true)
    } else {
      setIsSendNoticeButtonDisabled(false)
    }
  }

  const handleNoticeDetailChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    switch (event.target.id) {
      case 'reason':
        setNoticeDetails(prev => ({ ...prev, reason: event.target.value }))
        break
      case 'educationType':
        setNoticeDetails(prev => ({ ...prev, educationType: event.target.value }))
        break
      case 'graduationTime':
        setNoticeDetails(prev => ({ ...prev, timeStamp: event.target.value }))
        validateDate(event.target.value)
        break
      case 'issuer':
        setNoticeDetails(prev => ({ ...prev, issuer: event.target.value }))
        break
      case 'confirm-checkbox':
        setNoticeDetails(prev => ({ ...prev, isConfirmed: !prev.isConfirmed }))
    }
  }

  return (
    <div className={props.isOpen ? 'display-block' : 'display-none'}>
      <div className="modal-overlay" onClick={() => props.handleCloseModal()}></div>
      <div className="modal-main" onClick={e => e.stopPropagation()}>
        <div>
          <h1>
            <T>examination_phase.graduation_notice_modal.title</T>
          </h1>
        </div>
        <div>
          <ol>
            <li>
              <T>examination_phase.graduation_notice_modal.text_block_1a</T>
            </li>
            <li>
              <T>examination_phase.graduation_notice_modal.text_block_1b</T>
            </li>
            <li>
              <T>examination_phase.graduation_notice_modal.text_block_1c</T>
            </li>
          </ol>
          <div>
            <span>
              <T>examination_phase.graduation_notice_modal.text_block_2</T>
            </span>
          </div>
        </div>
        <div>
          <T>examination_phase.graduation_notice_modal.argument_input_box_info</T>
          <textarea
            value={noticeDetails.reason}
            id="reason"
            onChange={handleNoticeDetailChange}
            required
            className="modal-textarea"
          />
        </div>
        <div className="grid-container">
          <div>
            <T>examination_phase.graduation_notice_modal.education_type</T>
            <select
              value={noticeDetails.educationType}
              id="educationType"
              onChange={handleNoticeDetailChange}
              required
              className="education-type-select">
              <option defaultValue="yo" value="yo">
                {i18next.t('examination_phase.graduation_notice_modal.education_type_yo')}
              </option>
              <option value="vocational">
                {i18next.t('examination_phase.graduation_notice_modal.education_type_vocational')}
              </option>
              <option value="other">
                {i18next.t('examination_phase.graduation_notice_modal.education_type_other')}
              </option>
            </select>
          </div>
          <div>
            <label htmlFor="graduationTime">
              <T>examination_phase.graduation_notice_modal.graduation_time</T>
            </label>
            <input
              type="text"
              onChange={handleNoticeDetailChange}
              onSelect={() => setDateFieldState(prevState => ({ ...prevState, touched: true }))}
              value={noticeDetails.timeStamp}
              id="graduationTime"
              required
              className={dateFieldState.className}
            />
          </div>
        </div>
        <div style={{ paddingBottom: '25px' }}>
          <T>examination_phase.graduation_notice_modal.approvers_name</T>
          <input
            value={noticeDetails.issuer}
            type="text"
            id="issuer"
            onChange={handleNoticeDetailChange}
            className="modal-text-input"
          />
        </div>
        <div style={{ paddingBottom: '30px' }}>
          <input
            type="checkbox"
            id="confirm-checkbox"
            name="confirm-checkbox"
            onChange={handleNoticeDetailChange}
            checked={noticeDetails.isConfirmed}
            className="confirm-checkbox"
          />
          <span>
            <T>examination_phase.graduation_notice_modal.approve_checkbox</T>
          </span>
        </div>
        <div>
          <button
            id="confirm-button"
            className={isSendNoticeButtonDisabled ? 'confirm-button-disabled' : 'confirm-button'}
            onClick={() => void handleConfirm()}
            disabled={isSendNoticeButtonDisabled}>
            <T params={{ name: `${props.student.lastname} ${props.student.firstnames}` }}>
              examination_phase.graduation_notice_modal.confirm_button
            </T>
          </button>
          <button onClick={() => props.handleCloseModal()} className="cancel-button">
            <T>examination_phase.graduation_notice_modal.cancel_button</T>
          </button>
          {graduationNoticeFailed && (
            <label style={{ color: 'red' }} htmlFor="confirm-button">
              <b>
                <T>examination_phase.graduation_notice_modal.graduation_notice_failed</T>
              </b>
            </label>
          )}
        </div>
      </div>
    </div>
  )
}
