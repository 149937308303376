import React from 'react'
import { UserData } from './common/types'
import i18next from 'i18next'

import '../../less/header-banner.less'

export const PageBanner = ({ userData }: { userData: UserData | undefined }) => {
  const languages = ['fi', 'sv']

  return (
    <div className="header-banner" data-testid={'page-banner'}>
      <div className="organization">
        <div>Ylioppilastutkintolautakunta</div>
        <div>Studentexamensnämnden</div>
      </div>
      {userData?.user?.mock && (
        <a id="mock" href="/mock">
          change mock user
        </a>
      )}
      <div className="user-actions">
        <div className="language-selection">
          {languages.map(language => (
            <React.Fragment key={`language-${language}`}>
              <input
                type="radio"
                name="language"
                id={`language-${language}`}
                value={language}
                defaultChecked={i18next.language === language}
                onClick={e => changeLanguage(e.currentTarget.value)}
              />
              <label htmlFor={`language-${language}`}>{language.toUpperCase()}</label>
            </React.Fragment>
          ))}
        </div>

        {userData?.roles && (
          <div className="user-area">
            <span className="logged-in-user user-name">
              {userData?.user?.firstName} {userData?.user?.lastName}
            </span>
            <a
              id="vetuma-logout"
              data-testid={'logout'}
              onClick={() => window.location.assign('/kurko-api/user/logout')}
            />
          </div>
        )}
      </div>
    </div>
  )
  function changeLanguage(newLanguage: string) {
    i18next.changeLanguage(newLanguage).catch(err => console.error(err))
    document.querySelector('body')?.setAttribute('language', newLanguage)
  }
}
