import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as utils from '../common/client-utils-commonjs'
import { TabProps } from '../common/types'
import { useAxiosGet } from '../hooks'
import { LoadingSpinner } from '../common/loading-spinner'

type Language = 'fi' | 'sv'

type ExamEventResponse = {
  eventDate: string
  titles: {
    examEventTitleFi: string
    examEventTitleSv: string
  }
  multimebFilename: string
  exams: Exam[]
  hasRegistrations: boolean
}

type ExamEvent = ExamEventResponse & {
  eventDateStr: string
  title: string
  hasValidMeb: boolean
}

type Exam = {
  title: string
  examDate: string
  examRegCode: string
  isPaperExam: boolean
  nameFinnish: string
  nameSwedish: string
  hasRegistrations: boolean
}

export const ExamEvents = ({ scopeId, roleType }: TabProps) => {
  const [examEvents, setExamEvents] = useState<ExamEventResponse[]>([])

  const { t, i18n } = useTranslation()

  const [get, loading] = useAxiosGet()

  useEffect(() => {
    void (async () => {
      const examEvents = await get<ExamEventResponse[]>(`/exam-api/exams/exam-events/${scopeId}`)
      setExamEvents(examEvents ? examEvents : [])
    })()
  }, [scopeId, roleType])

  const isAssistant = roleType === 'ASSISTANT'

  return (
    <div id="exam-events-tab" className="tab">
      <LoadingSpinner loading={loading} />
      <div className="digital-exams-publication-notification">{t('sa.digital_exams_publication_info')}</div>
      <table id="exam-events">
        <thead>
          <tr>
            <th className="event-date-col">{t('sa.exam_date')}</th>
            <th className="event-exams-col">{t('sa.exams_of_event')}</th>
            <th className="event-files-col">{t('sa.files')}</th>
          </tr>
        </thead>
        {formatExamEvents(examEvents, i18n.language as Language).map((examEvent, index) => (
          <ExamEvent key={index} {...examEvent} schoolId={scopeId} isAssistant={isAssistant} />
        ))}
      </table>
    </div>
  )
}

type ExamEventProps = ExamEvent & { schoolId: string; isAssistant: boolean }

const ExamEvent = (props: ExamEventProps) => {
  const { eventDateStr, hasRegistrations, exams } = props
  return (
    <tbody className="exam-event" data-event-date={eventDateStr}>
      <ExamEventHeader {...props} />
      {!hasRegistrations && <PaperExams />}
      {exams && <Exams {...props} />}
    </tbody>
  )
}

const Exams = ({ exams, hasValidMeb, isAssistant, multimebFilename, schoolId, eventDate }: ExamEventProps) => {
  const { t } = useTranslation()

  return (
    <tr className="exam-event exams-row">
      <td></td>
      <td className="exams-list">
        <ul className="exams-of-event">
          {exams.map(({ title }, index) => (
            <li key={index} className="exam">
              <div className="name">{title}</div>
            </li>
          ))}
          {isAssistant && <div className="no-meb-download-rights">{t('sa.no_access_rights_for_meb_download')}</div>}
        </ul>
      </td>
      <td className="download-exam">
        {!isAssistant && hasValidMeb && (
          <>
            <a href={`/exam-api/exams/exam-event/mex/${multimebFilename}`}>
              <button className="download-exam-button" type="button">
                {t('sa.download_exam')}
              </button>
            </a>
            <a href={`/exam-api/exams/exam-event/registrations/${schoolId}/${eventDate}`}>
              <button className="download-registrations-button" type="button">
                {t('sa.download_registrations')}
              </button>
            </a>
          </>
        )}
      </td>
    </tr>
  )
}

const ExamEventHeader = ({ eventDateStr, eventDate, title, hasRegistrations, schoolId }: ExamEventProps) => {
  const { t } = useTranslation()
  return (
    <tr className="exam-event-header-row">
      <th>{eventDateStr}</th>
      <th className="js-exam-event-title">{title}</th>
      <th className="download-minutes">
        {hasRegistrations && (
          <a href={`/exam-api/exams/exam-event/minutes/${schoolId}/${eventDate}`}>
            <button className="download-minutes-button" type="button">
              {t('sa.download_minutes')}
            </button>
          </a>
        )}
      </th>
    </tr>
  )
}

const PaperExams = () => {
  const { t } = useTranslation()
  return (
    <tr className="exam-event paper-exams-row">
      <td></td>
      <td className="exams-list">
        <span className="no-paper-exam-registrations">{t('sa.no_registrations_to_exam_event')}</span>
      </td>
      <td className="download-grading-form"></td>
    </tr>
  )
}

function formatExamEvents(examEvents: ExamEventResponse[], lang: Language) {
  return examEvents.map<ExamEvent>(examEvent => ({
    ...examEvent,
    eventDateStr: toFinnishDate(examEvent.eventDate),
    exams: examTitleByLanguageForExams(examEvent.exams, lang),
    title: eventTitleByLanguage(examEvent, lang),
    hasValidMeb: examEvent.multimebFilename !== null
  }))
}

function toFinnishDate(YYYY_MM_DD: string) {
  return utils.yyyyMmDdStringToFinnishDateString(YYYY_MM_DD)
}

function examTitleByLanguageForExams(exams: Exam[], lang: Language) {
  return exams
    .map(exam => ({
      ...exam,
      title: examTitleByLanguage(exam, lang)
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1))
}

function examTitleByLanguage(exam: Exam, lang: Language) {
  const languageMapping = { fi: 'nameFinnish', sv: 'nameSwedish' }
  return exam[languageMapping[lang] as keyof { nameFinnish: string; nameSwedish: string }]
}

function eventTitleByLanguage(event: ExamEventResponse, lang: Language) {
  const languageMapping = { fi: 'examEventTitleFi', sv: 'examEventTitleSv' }
  return Object.keys(event.titles)
    ? event.titles[languageMapping[lang] as keyof { examEventTitleFi: string; examEventTitleSv: string }]
    : ''
}
