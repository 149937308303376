import React from 'react'
import { T } from './common/translate'

export const NoAccess = () => (
  <>
    <h1>
      <T>sa.yo.no-schools.title</T>
    </h1>
    <p>
      <T>sa.yo.no-schools.description</T>
    </p>
    <h2>
      <T>sa.yo.no-schools.what-next</T>
    </h2>
    <h3>
      <T>sa.yo.no-schools.principal</T>
    </h3>
    <p>
      <T>sa.yo.no-schools.principal-instruction</T>
    </p>
    <h3>
      <T>sa.yo.no-schools.teacher</T>
    </h3>
    <p>
      <T>sa.yo.no-schools.teacher-instruction</T>
    </p>
    <button className="back-to-login" onClick={() => window.location.assign('/kurko-api/user/logout')}>
      <T>sa.yo.no-schools.back-to-login-button</T>
    </button>
  </>
)
