import Cookies from 'js-cookie'

const COLLAPSABLE_TABLE_STATUS_COOKIE_NAME = 'COLLAPSABLE_TABLE_STATUS_COOKIE_NAME'
const DEFAULT_COLLAPSED = true

type CollapsableTable = {
  [collapsableId: string]: boolean
}

const getCookie = () => {
  const value = Cookies.get(COLLAPSABLE_TABLE_STATUS_COOKIE_NAME)
  return ((value && JSON.parse(value)) || {}) as CollapsableTable
}

export function getInitialStatus(collapsableId: string) {
  const cookie = getCookie()
  return cookie[collapsableId] === undefined ? DEFAULT_COLLAPSED : cookie[collapsableId]
}

export function toggleCollapseTableCookie(collapsableId: string, collapsed: boolean) {
  const oldCookie = getCookie()
  const newCookie = { ...oldCookie, [collapsableId]: collapsed }
  Cookies.set(COLLAPSABLE_TABLE_STATUS_COOKIE_NAME, JSON.stringify(newCookie))
}
