export const pregradingExamUrls = {
  uploadAnswers: (schoolId: string) => `/exam-api/answer-packages/school/${schoolId}`,
  heldExams: (schoolId: string) => `/exam-api/exams/${schoolId}/held-exams`,
  grading: (schoolAnonCode: string) => `/school/grading/${schoolAnonCode}`,
  reviewAnswers: (_schoolAnonCode: string) => '#',
  deleteAnswers: (_schoolAnonCode: string) => '#',
  undeleteAnswers: (_schoolAnonCode: string) => '#',
  examsWithGrader: (scopeId: string) => `/exam-api/grading/${scopeId}/exams-with-grader`,
  unmappedStudents: (schoolId: string) => `/exam-api/grading/${schoolId}/unmapped-students`
}
