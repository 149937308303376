import React, { useEffect, createContext } from 'react'
import { T, translate, useLanguage } from './translate'

export const FloatingErrorContext = createContext<(error: string) => void>(() => {})

export const FloatingError = ({ currentError }: { currentError: string | null }) => {
  useLanguage()
  useEffect(() => {
    document.body.style.overflow = currentError ? 'hidden' : 'auto'
  }, [currentError])

  if (!currentError) return null

  return (
    <>
      <div id="disabled-background" tabIndex={-1}></div>
      <div id="floating-error">
        <div className="error-symbol">&#x26A0;</div>
        <div className="message localized-error" dangerouslySetInnerHTML={{ __html: translate(currentError) }} />
        <button id="logout-on-error" onClick={() => window.location.assign('/kurko-api/user/logout')}>
          <T>sa.logout</T>
        </button>
        <button id="reload-page" onClick={() => location.reload()}>
          <T>arpa.errors.reload_page</T>
        </button>
      </div>
    </>
  )
}
