import React, { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import uri from 'uri-tag'
import _ from 'lodash'
import { T, translate } from '../common/translate'
import i18next from 'i18next'

interface ExaminationDetailsApi {
  studentDetails: StudentDetails[]
  studentUuid: string
}

interface StudentDetails {
  earlyTermination?: string
  extraTimePeriods: ExtraTimePeriod[]
  isRejected: boolean
  lastNew: string
  registrationsByExamination: Registration[]
  studentExaminationsId: number
  currentIncludedExams?: IncludedExam[]
}

interface Registration {
  examinationId: number
  examinationcode: string
  exams: Exam[]
  includedExams: IncludedExam[]
  freeExamsLeft?: number
}

interface ExtendedRegistration extends Registration {
  isExtraTimePeriod: false
}

interface ExtraTimePeriod {
  examinationcode: string
  isExtraTimePeriod: true
}

interface Exam {
  examUuid: string
  examinationId: number
  examinationcode: string
  isAborted: boolean
  isFinished: boolean
  isInvalidated: boolean
  isMandatory: boolean
  retryAttemptNumber: number | null
  nameFinnish: string
  nameSwedish: string
  grade?: string
  examDetailsYtlRegCode: string
  free: boolean
}

interface IncludedExam {
  examDetailsYtlRegCode: string
  details: {
    lastPeriod: string
    grade?: string
  }[]
  examUuid: string
  nameFinnish: string
  nameSwedish: string
}

interface ExpandableRowTableProps {
  expanded: boolean
  studentUuid: string
  isLaw2022Student: boolean
}

interface StudentExaminationContentProps {
  examinationPeriods: (ExtendedRegistration | ExtraTimePeriod)[]
  isLaw2022Student: boolean
}

interface LocalTranslation {
  fi: string
  sv: string
}

// Return a string matching to currently used language
const localized = (keys: LocalTranslation): string | undefined => keys[i18next.language as keyof LocalTranslation]

const localizedPeriod = (periodFi: string): string => {
  if (i18next.language === 'sv') {
    return periodFi.replace('S', 'H').replace('K', 'V')
  }
  return periodFi
}

const localizedPeriodString = (periodFi: string): string => {
  if (periodFi.endsWith('S')) {
    return periodFi.replace('S', ` ${translate('autumn').toLowerCase()}`)
  } else if (periodFi.endsWith('K')) {
    return periodFi.replace('K', ` ${translate('spring').toLowerCase()}`)
  }
  return periodFi
}

export function ExaminationHistory(props: ExpandableRowTableProps) {
  const [studentDetails, setStudentDetails] = useState<StudentDetails[] | null>(null)
  const instance = axios.create()
  instance.interceptors.response.use(_.identity, err => {
    const error = err as AxiosError
    if (error?.response?.status === 401) {
      window.location.replace('/')
    }
    return error
  })

  useEffect(() => {
    if (!studentDetails && props.expanded) {
      void (async () => {
        const response = await instance.get<ExaminationDetailsApi>(
          uri`/student/${props.studentUuid}/examination-details`
        )
        setStudentDetails(response.data.studentDetails)
      })()
    }
  }, [props.expanded])

  if (!props.expanded || !studentDetails) {
    return null
  }

  const examinations = studentDetails.map((data, index: number) => {
    const {
      studentExaminationsId,
      lastNew,
      registrationsByExamination,
      isRejected,
      earlyTermination,
      extraTimePeriods,
      currentIncludedExams
    } = data

    const filteredRegistrations = registrationsByExamination
      .filter(r => !extraTimePeriods.some(e => e.examinationcode === r.examinationcode))
      .map(registration => ({ ...registration, isExtraTimePeriod: false }) as ExtendedRegistration)

    const examinationPeriods = _.orderBy([...filteredRegistrations, ...extraTimePeriods], ['examinationcode'], ['desc'])

    const examinationCodes = registrationsByExamination
      .map(registration => registration.examinationcode)
      .sort()
      .join(', ')

    const examinationClassName = isRejected || earlyTermination ? 'rejected-examination' : ''
    const showLastNewTitle = index === 0 && lastNew !== null
    const freeExamsLeft = registrationsByExamination[0]?.freeExamsLeft

    const freeExamsLeftColumn =
      freeExamsLeft == undefined ? (
        <T>examination_phase.is_not_free_education</T>
      ) : (
        <>
          {freeExamsLeft} <T>examination_phase.pcs</T>
        </>
      )

    return (
      <div key={studentExaminationsId} className={`student-exam-details-table-border ${examinationClassName}`}>
        {(isRejected || earlyTermination) && (
          <div className="examination-status-title">
            <h2>
              <T>{isRejected ? 'examination_phase.rejected' : 'examination_phase.terminated'}</T>
            </h2>
          </div>
        )}
        <h1 className="expanded-row-title">
          <T>examination_phase.candidate</T>
          {` ${localizedPeriod(examinationCodes)}`}
        </h1>
        {showLastNewTitle && (
          <div className="expanded-row-title-last-round">
            <T>examination_phase.last_round</T>
            {` ${localizedPeriodString(lastNew)}`}
          </div>
        )}
        <div className="expanded-row-title-free-exams-left">
          {index == 0 && (
            <>
              <T>examination_phase.free_exams_left</T>: {freeExamsLeftColumn}
            </>
          )}
        </div>
        <ExaminationContent examinationPeriods={examinationPeriods} isLaw2022Student={props.isLaw2022Student} />
        {currentIncludedExams && currentIncludedExams.length > 0 && (
          <div>
            <h1 className="included-exams-title">
              <T>examination_phase.included_exams</T>
            </h1>
            <table className="student-exam-details-table">
              <tbody>
                {currentIncludedExams.map(exam => (
                  <IncludedExamDetails key={exam.examUuid} exam={exam} />
                ))}
              </tbody>
            </table>
          </div>
        )}{' '}
      </div>
    )
  })

  return (
    <td className="expanded-table-row" style={{ paddingTop: '0px' }} colSpan={7}>
      {examinations}
    </td>
  )
}

function ExaminationContent(props: StudentExaminationContentProps) {
  const { examinationPeriods } = props
  const examinationContent = examinationPeriods.map(period => {
    if (period.isExtraTimePeriod) {
      return <ExtraTimePeriodContent extraTimePeriod={period} key={period.examinationcode} />
    }

    return (
      <ExaminationPeriod registration={period} key={period.examinationId} isLaw2022Student={props.isLaw2022Student} />
    )
  })

  return <div>{examinationContent}</div>
}

function ExtraTimePeriodContent(props: { extraTimePeriod: ExtraTimePeriod }) {
  const { extraTimePeriod } = props
  return (
    <div className="student-exam-details-table-border" key={extraTimePeriod.examinationcode}>
      <div className="extra-time-header student-exam-details-table-border">
        <div className="examination-status-title">
          <h3>
            <T>examination_phase.extratime</T>
          </h3>
        </div>
        <h3 className="expanded-row-table-examinationcode extratime-period">
          {localizedPeriodString(extraTimePeriod.examinationcode)}
        </h3>
      </div>
    </div>
  )
}

function ExaminationPeriod(props: { registration: Registration; isLaw2022Student: boolean }) {
  const { registration } = props
  const exams = registration.exams.map(exam => (
    <ExamDetails key={exam.examUuid} exam={exam} isLaw2022Student={props.isLaw2022Student} />
  ))

  return (
    <div className="student-exam-details-table-border">
      <div className="student-exam-details-table-border">
        {!registration.exams && (
          <div className="examination-status-title">
            <h3>
              <T>examination_phase.no_registrations</T>
            </h3>
          </div>
        )}
        <h3 className="expanded-row-table-examinationcode">{localizedPeriodString(registration.examinationcode)}</h3>
        <table className="student-exam-details-table">
          <tbody>{exams}</tbody>
        </table>
      </div>
    </div>
  )
}

function IncludedExamDetails(props: { exam: IncludedExam }) {
  const { exam } = props
  const getExamDetailElements = () =>
    exam.details.map((d, index) => (
      <span key={d.grade}>
        {d.grade} ({<T params={{ period: d.lastPeriod }}>examination_phase.inclusion_expires</T>})
        {exam.details.length - 1 === index ? '' : ', '}
      </span>
    ))

  const detailElements = getExamDetailElements()

  return (
    <tr key={exam.examUuid}>
      <td className="reg-code">{exam.examDetailsYtlRegCode}</td>
      <td className="subject">{localized({ sv: exam.nameSwedish, fi: exam.nameFinnish })}</td>
      <td>{detailElements}</td>
      <td style={{ width: '20%' }} />
    </tr>
  )
}

function ExamDetails(props: { exam: Exam; isLaw2022Student: boolean }) {
  const {
    exam,
    exam: { isAborted, isInvalidated }
  } = props

  const isEitherAbortedOrInvalidated = isAborted || isInvalidated
  const displayedGrade = isEitherAbortedOrInvalidated ? '-' : exam.grade
  const isRowGreyedOut = isEitherAbortedOrInvalidated || exam.grade?.startsWith('I')

  const getGradeColumnValue = () => {
    if (isEitherAbortedOrInvalidated) {
      return <T>{isInvalidated ? 'examination_phase.invalidated' : 'examination_phase.aborted'}</T>
    }
    return displayedGrade
  }

  const getPre2022StudentExamSuffix = () => {
    if (props.isLaw2022Student) {
      return ''
    }

    return exam.isMandatory ? (
      <>
        (<T>registration.exam_mandatory</T>)
      </>
    ) : (
      <>
        (<T>registration.exam_extra</T>)
      </>
    )
  }

  const isRetryAttempt = !!exam.retryAttemptNumber
  const maybeEuroSign = exam.free ? null : <span className="euro-sign" />

  return (
    <tr key={exam.examUuid} className={isRowGreyedOut ? 'failed-subject' : undefined}>
      <td className="reg-code">{exam.examDetailsYtlRegCode}</td>
      <td className="subject">
        {localized({ sv: exam.nameSwedish, fi: exam.nameFinnish })} {getPre2022StudentExamSuffix()} {maybeEuroSign}
      </td>
      <td className="grade">{getGradeColumnValue()}</td>
      <td className="retry-attempt">
        {isRetryAttempt && (
          <div>
            {exam.retryAttemptNumber}. <T>examination_phase.retry_attempt</T>
          </div>
        )}
      </td>
    </tr>
  )
}
