import React from 'react'
import { T } from '../common/translate'

export function RegistryErrorNotification() {
  return (
    <div className="registry-error-notification">
      <T>registration.registry_error</T>
    </div>
  )
}
