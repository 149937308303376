import classNames from 'classnames'
import $ from 'jquery'
import React, { MouseEvent, useEffect, useRef } from 'react'
import * as spinnerUtils from '../common/spinner-utils'
import { T } from '../common/translate'

interface UploadRegistrationsProps {
  language: string
  schoolId: string
  roleType?: string
  postRegistrationJson: (json: string) => Promise<void>
  uploading: boolean
  error: string
  dryRun: boolean
}

export function UploadRegistrations(props: UploadRegistrationsProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.uploading) {
      startSpinner()
    } else {
      stopSpinner()
    }
  }, [props.uploading])

  const onUploadInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()

    if (!event.target.files || props.roleType == 'STUDENTCOUNSELLOR') {
      return
    }

    reader.readAsText(event.target.files[0])
    reader.onloadend = () => {
      void props.postRegistrationJson(reader.result as string)
    }
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (hiddenFileInput.current === null || props.roleType == 'STUDENTCOUNSELLOR') {
      return
    }

    hiddenFileInput.current.click()
  }

  const startSpinner = () => {
    const $uploadForm = $('.registrations-upload-panel')
    spinnerUtils.ui.startSpinner($uploadForm.find('.upload-spinner'), spinnerUtils.ui.spinnerYtlVerySmallOpts)
    $uploadForm.find('.upload-takes-long-container').css('display', 'inline-block')
  }

  const stopSpinner = () => {
    const $uploadForm = $('.registrations-upload-panel')
    $uploadForm.find('.upload-input').val('')
    spinnerUtils.ui.stopSpinner($uploadForm.find('.upload-spinner'))
    $uploadForm.find('.upload-takes-long-container').hide()
  }

  const { dryRun } = props

  return (
    <div className="registrations-upload">
      <form className="registrations-upload-panel" name="registration-upload" method="POST" action="">
        <div className="registration-not-sent">
          {!dryRun && <T>registration.upload_instructions</T>}
          {dryRun && (
            <>
              <p>
                <T>registration.upload_instructions_dry_run</T>
              </p>
              <p className="dry-run-title">
                <T>registration.upload_instructions_dry_run_2</T>
              </p>
            </>
          )}
        </div>
        <div className="registration-upload-button-wrapper">
          <button
            onClick={handleClick}
            className={classNames(dryRun ? 'dry-run-upload-button' : 'upload-button', 'js-registration-upload-button')}
            disabled={props.uploading || props.roleType == 'STUDENTCOUNSELLOR'}>
            {!dryRun && <T>registration.registration_upload</T>}
            {dryRun && <T>registration.registration_upload_dry_run</T>}
          </button>
          <div className="upload-takes-long-container">
            <span className="upload-spinner" />
            <div className="notification-text">
              <T>registration.registration_upload_takes_time</T>
            </div>
          </div>
          <div className="upload-error error-notice">{props.error}</div>
          <input
            className="upload-input"
            onChange={onUploadInputChange}
            ref={hiddenFileInput}
            type="file"
            accept=".json"
          />
        </div>
      </form>
    </div>
  )
}
