import React, { createContext, useEffect, useRef, useState } from 'react'
import { T, translate, useLanguage } from '../common/translate'
import * as L from 'partial.lenses'
import { PaymentInformation } from './payment-information'
import { generateReference } from './generate-reference'
import { AcceptedRegistrations } from './accepted-registrations'
import { UploadRegistrations } from './upload-registrations'
import { RegistrationErrorTable, RegistrationWarningTable } from './registration-errors'
import * as studentsUtil from './students-util'
import axios, { AxiosResponse } from 'axios'
import uri from 'uri-tag'
import { RegistryErrorNotification } from './registry-error-notification'
import i18next from 'i18next'

import isUUID from 'validator/lib/isUUID'
import { TabProps } from '../common/types'
import { useAxiosGet } from '../hooks'
import { LoadingSpinner } from '../common/loading-spinner'
import { DryRunStudents } from './dry-run-students'
import moment from 'moment'
export const hasUnknownNames = L.or(L.flat('unknownName'))

function registrationTitleData(registrationStatus?: RegistrationStatusResponse) {
  const matches = RegExp(/(\d{4})([KS])/).exec(registrationStatus ? registrationStatus.examinationcode : '')

  return {
    examinationPeriodSeasonIsAutumn: matches ? matches[2] === 'S' : null,
    examinationPeriodYear: matches ? matches[1] : null
  }
}

export interface RegistrationStatusResponse {
  examinationcode: string
  pending: boolean
  registered: boolean
  schoolUuid: string
  schoolYtlNumber: number
  students: Student[]
  timestamp: string | null
}
export interface RegistrationPeriodResponse {
  dryRunStartDate: string | null
  dryRunEndDate: string | null
  startDate: string | null
  endDate: string | null
  registrationOpen: boolean
  dryRunOpen: boolean
}

export interface Student {
  examinationType: string
  exams: Exam[]
  firstnames: string
  firstname: string
  unknownName?: string
  incompleteExaminationReasons: string[]
  isLaw2022Student: boolean
  isRestarter: boolean
  lastname: string
  ophIdNumber: number
  schoolYtlNumber: number
  schoolName?: string
  ssn: string
  studentNumber: string
  studentUuid: string
  studyType: string
  totalFee: number
  principalNoticeDate: string
  ophStudiesCompletedDate: string
  freeExamsLeft?: number
  errors: { code: number; msg: string }[]
  warnings?: { code: number; msg: string; overrideExplanation?: string }[]
  freeEducationOverridePermitExplanation: string | null
}

interface Exam {
  examDetailsNameFinnish: string
  examDetailsNameSwedish: string
  examRoleTypeCode: string
  invalidated: boolean
  mandatory: boolean
  regCode: string
  free: boolean
  registrationInvalidated: boolean
}

function getLanguage() {
  if (i18next.language === 'sv') {
    return 'swe'
  }
  return 'fin'
}

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    throw error
  }
)

export const client = axios.create({
  baseURL: '',
  headers: { 'Content-Type': 'application/json' }
})

type RegistrationData = {
  examinationPeriod: string
  isAhvenanmaa: boolean
  updateStudent: (student: Student) => void
}

export const RegistrationContext = createContext<RegistrationData>({} as RegistrationData)

export function Registration({ scopeId, roleType }: TabProps) {
  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatusResponse>()
  const [dryRunStatus, setDryRunStatus] = useState<RegistrationStatusResponse>()
  const [regPeriodInfo, setRegPeriodInfo] = useState<RegistrationPeriodResponse>()
  const [get, loading] = useAxiosGet()
  const [registrationErrors, setRegistrationErrors] = useState('')
  const [dryRunErrors, setDryRunErrors] = useState('')
  const [students, setStudents] = useState<Student[]>([])
  const [dryRunStudents, setDryRunStudents] = useState<Student[]>([])
  const [transferableStudent, setTransferableStudent] = useState<Student | null>(null)
  const [transferError, setTransferError] = useState('')
  const [uploadFailed, setUploadFailed] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [studentTransfer, setStudentTransfer] = useState(false)
  const [dryRun, setDryRun] = useState(false)
  const [dryRunStatusTrigger, setDryRunStatusTrigger] = useState(false)
  const studentNumberRef = useRef<HTMLSelectElement>(null)
  const seasonAndYear = registrationTitleData(registrationStatus)
  const studentFeeTotalForSchool = L.sum(L.flat('totalFee'), students).toLocaleString('fi', {
    useGrouping: true
  })
  const lang = useLanguage()
  const isLanguageFinnish = lang === 'fi'

  function formatStudents(students: Student[]) {
    return (studentsUtil as { prepareForRender: (student: Student[]) => Student[] }).prepareForRender(students)
  }

  useEffect(() => {
    void (async () => {
      const data = await get<RegistrationStatusResponse>(
        `/registration/${scopeId}/registration-status`,
        undefined,
        'sa.errors.load_error',
        {
          headers: { 'x-lang': lang }
        }
      )
      if (data) {
        setStudents(formatStudents(data.students))
        setRegistrationStatus(data)
      }
    })()
  }, [scopeId, lang])

  useEffect(() => {
    void (async () => {
      const data = await get<RegistrationStatusResponse>(
        `/registration/${scopeId}/dry-run-status`,
        undefined,
        'sa.errors.load_error',
        {
          headers: { 'x-lang': lang }
        }
      )
      if (data) {
        setDryRunStudents(formatStudents(data.students))
        setDryRunStatus(data)
      }
    })()
  }, [scopeId, lang, dryRunStatusTrigger])

  useEffect(() => {
    void (async () => {
      const data = await get<RegistrationPeriodResponse>(
        `/registration/registration-period-info`,
        undefined,
        'sa.errors.load_error'
      )
      if (data) {
        setRegPeriodInfo(data)
      }
    })()
  }, [])

  const paymentInfo = {
    examinationPeriodSeasonIsAutumn: seasonAndYear.examinationPeriodSeasonIsAutumn!,
    examinationPeriodYear: seasonAndYear.examinationPeriodYear!,
    referenceCode: generateReference(990, registrationStatus ? registrationStatus.schoolYtlNumber : 0),
    studentFeeTotalForSchool
  }

  const changeUploadingState = () => {
    setUploading(prev => !prev)
  }

  const triggerDryRunStatusFetch = () => {
    setDryRunStatusTrigger(prev => !prev)
  }

  const clearErrors = () => {
    setDryRunErrors('')
    setRegistrationErrors('')
  }

  const handleErrorResponse = (errorResponse: any, endPoint: string) => {
    if (axios.isAxiosError(errorResponse)) {
      const { data } = errorResponse.response! as { data: { students?: Student[]; message: string } }
      if (data.students) {
        if (endPoint === 'dry-run') {
          // error response does not contain expanded warning messages and timestamps, it's easier to just fetch
          // the status again than to shoehorn the stuff in backend
          triggerDryRunStatusFetch()
        } else {
          setRegistrationStatus({ ...registrationStatus!, students: data.students })
          setStudents(formatStudents(data.students))
        }
        return
      }

      if (endPoint === 'dry-run') {
        setDryRunErrors(data.message)
      } else {
        setRegistrationErrors(data.message)
      }

      return
    }
    setUploadFailed(true)
  }

  const postRegistrationJsonFn = (endPoint: string) => async (registrationString: string) => {
    try {
      changeUploadingState()
      clearErrors()
      if (endPoint === 'dry-run') {
        setDryRunStudents([])
      }
      const response = await client.post<RegistrationStatusResponse>(
        uri`/registration/${scopeId}/${endPoint}`,
        registrationString,
        { headers: { 'x-lang': getLanguage() } }
      )
      setTimeout(() => {
        changeUploadingState()
        if (endPoint === 'dry-run') {
          setDryRunStatus(response.data)
          setDryRunStudents(formatStudents(response.data.students))
        } else {
          setRegistrationStatus(response.data)
          setStudents(formatStudents(response.data.students))
        }
      }, 1500)
    } catch (error) {
      setTimeout(() => {
        handleErrorResponse(error, endPoint)
        changeUploadingState()
      }, 1500)
    }
  }

  const DryRunNavigation = () => (
    <div className="dry-run-navi">
      <a className={!dryRun ? 'selected' : ''} onClick={() => setDryRun(false)}>
        <T>registration.dry_run.link_registration</T>
      </a>
      <a className={dryRun ? 'selected' : ''} onClick={() => setDryRun(true)}>
        <T>registration.dry_run.link_dry_run</T>
      </a>
    </div>
  )

  const SeasonAndYear = () => (
    <h3>
      {seasonAndYear.examinationPeriodYear && (
        <div>
          {seasonAndYear.examinationPeriodSeasonIsAutumn ? (
            <span>
              <T>autumn</T>
            </span>
          ) : (
            <span>
              <T>spring</T>
            </span>
          )}
          &nbsp;
          <span>{seasonAndYear.examinationPeriodYear}</span>
        </div>
      )}
    </h3>
  )

  const updateStudentState = (student: Student) => {
    setStudents(students.map(s => (s.ssn === student.ssn ? student : s)))
  }

  if (registrationStatus && dryRunStatus && !registrationStatus.registered && regPeriodInfo) {
    const displayUploadButton = (dryRun && regPeriodInfo.dryRunOpen) || (!dryRun && regPeriodInfo.registrationOpen)

    return (
      <RegistrationContext.Provider
        value={{
          examinationPeriod: registrationStatus.examinationcode,
          isAhvenanmaa: registrationStatus.schoolYtlNumber === 2962,
          updateStudent: updateStudentState
        }}>
        <div id="registration-tab" className="tab">
          <DryRunNavigation />
          {uploadFailed && <RegistryErrorNotification />}

          {!regPeriodInfo.registrationOpen && !dryRun && (
            <p>
              <T>registration.registration_closed</T> {moment(regPeriodInfo.startDate).format('D.M.YYYY')}
            </p>
          )}

          {!regPeriodInfo.dryRunOpen && dryRun && (
            <p>
              <T>registration.dry_run_closed</T>
            </p>
          )}

          {displayUploadButton && (
            <>
              <UploadRegistrations
                language={lang}
                schoolId={registrationStatus.schoolUuid}
                roleType={roleType}
                postRegistrationJson={postRegistrationJsonFn(dryRun ? 'dry-run' : 'register')}
                uploading={uploading}
                error={dryRun ? dryRunErrors : registrationErrors}
                dryRun={dryRun}
              />
              {!dryRun && (
                <RegistrationErrorTable
                  students={students}
                  hasUnknownNames={hasUnknownNames(students)}
                  dryRun={dryRun}
                />
              )}
              {dryRun && dryRunStudents?.length > 0 && (
                <>
                  <SeasonAndYear />
                  <div className="dry-run-results">
                    <DryRunStudents students={dryRunStudents} timestamp={dryRunStatus.timestamp} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </RegistrationContext.Provider>
    )
  }

  const ActionButtons = () => (
    <p className="action-buttons">
      <button
        onClick={() => window.open(`/registration/${scopeId}/fees`)}
        className="auto-sa-button"
        data-testid="registration-fees">
        <T>registration.load_registration_json</T>
      </button>
      {!studentTransfer && (
        <button className="auto-sa-button" onClick={() => setStudentTransferState(true)}>
          <T>registration.transfer.button</T>
        </button>
      )}
    </p>
  )
  const setStudentTransferState = (newState: boolean) => {
    setStudentTransfer(newState)
  }

  const fetchStudent = (transferToken: string) => {
    setTransferError('')
    if (!transferToken) return
    if (isUUID(transferToken)) {
      client
        .get<Student>(`/registration/transfer/get-student/${transferToken}`)
        .then(response => {
          if (students.map(student => student.studentUuid).includes(response.data.studentUuid)) {
            setTransferError('registration.transfer.failure.own_student')
            return
          }
          const formatted = formatStudents([response.data])
          return setTransferableStudent(formatted[0])
        })
        .catch(err => {
          setTransferError('registration.transfer.failure.search_student')
          setTransferableStudent(null)
        })
    } else {
      setTransferError('registration.transfer.failure.invalid_token')
      setTransferableStudent(null)
    }
  }

  const transferStudent = () => {
    client
      .post(
        `/registration/transfer/move-student/${transferableStudent!.studentUuid}/${registrationStatus!.schoolUuid}/${
          studentNumberRef.current?.value || '-'
        }`
      )
      .then(() => window.location.reload())
      .catch(err => {
        setTransferError('registration.transfer.failure.transfer_student')
      })
  }

  const closeTransferButton = (buttonTextTranslationKey: string) => (
    <button
      onClick={() => {
        setStudentTransferState(false)
        setTransferableStudent(null)
      }}>
      <T>{buttonTextTranslationKey}</T>
    </button>
  )

  const availableStudentNumbers = () => {
    const preservedStudentNumbers = students.map(student => student.studentNumber)
    const maxStudentNumber = preservedStudentNumbers.reduce(
      (acc, studentNumber) => (acc < studentNumber ? studentNumber : acc),
      '001'
    )
    const allStudentNumbers = Array.from(Array(Number(maxStudentNumber)).keys()).map(n => {
      const nr = `000${n + 1}`
      return nr.substring(nr.length - 3)
    })
    return allStudentNumbers.filter(studentNumber => !preservedStudentNumbers.includes(studentNumber))
  }

  const StudentNumberChoices = () => {
    const studentNumbers = availableStudentNumbers()
    return studentNumbers.length ? (
      <>
        <T>registration.transfer.student_number_suggestion</T>
        &nbsp;
        <select ref={studentNumberRef}>
          <option value="-">{translate('registration.transfer.student_number_automatic')}</option>
          {studentNumbers.map(availableStudentNumber => (
            <option key={availableStudentNumber}>{availableStudentNumber}</option>
          ))}
        </select>
      </>
    ) : null
  }

  // if we're here, registrations have been successfully uploaded. Dry run is closed, and we show the regs.
  return (
    <div id="registration-tab" className="tab">
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div>
          <DryRunNavigation />

          {dryRun && (
            <p>
              <T>registration.dry_run_closed</T>
            </p>
          )}

          {!dryRun && (
            <>
              <PaymentInformation paymentInfo={paymentInfo} />
              <ActionButtons />
              <RegistrationWarningTable
                students={students}
                hasUnknownNames={hasUnknownNames(students)}
                showMessage={true}
              />
              {studentTransfer && (
                <div className="student-transfer">
                  <b>
                    <T>registration.transfer.title</T>
                  </b>
                  <p>
                    <T>registration.transfer.description1</T>
                  </p>
                  <p>
                    <T>registration.transfer.description2</T>
                  </p>
                  <p>
                    <b>
                      <T>registration.transfer.receive</T>
                    </b>
                    <br />
                    <T>registration.transfer.type_code</T>
                    &nbsp;
                    <input
                      className={transferError == 'registration.transfer.failure.invalid_token' ? 'error' : ''}
                      onChange={e => fetchStudent(e.target.value.trim())}
                    />
                  </p>
                  {studentTransfer && transferableStudent && (
                    <div className="student-transfer">
                      <p>
                        <T>registration.transfer.from_school</T>: <b>{transferableStudent?.schoolName}</b>
                      </p>
                      <p>
                        <b>{transferableStudent.lastname}</b>, {transferableStudent.firstnames}
                      </p>
                      <AcceptedRegistrations
                        students={[transferableStudent]}
                        hasUnknownNames={false}
                        isFin={isLanguageFinnish}
                        studentFeeTotalForSchool={''}
                        studentTransfer={false}
                        mode="transfer"
                      />
                      <p>
                        <StudentNumberChoices />
                        <button className="auto-sa-button" onClick={() => transferStudent()}>
                          <T>registration.transfer.approve</T>
                        </button>
                        {closeTransferButton('registration.transfer.cancel')}
                      </p>
                    </div>
                  )}
                  {transferError && (
                    <p className="error">
                      <T>{transferError}</T>
                    </p>
                  )}
                  <p>{!transferableStudent && closeTransferButton('registration.transfer.close')}</p>
                </div>
              )}
              <SeasonAndYear />
              <AcceptedRegistrations
                students={students}
                hasUnknownNames={hasUnknownNames(students)}
                isFin={isLanguageFinnish}
                studentFeeTotalForSchool={studentFeeTotalForSchool}
                studentTransfer={studentTransfer}
                mode="registrations"
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}
