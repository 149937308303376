import React, { useEffect, useState } from 'react'
import uri from 'uri-tag'
import { ResultListApi, ResultListGroup } from './result-list-types'
import { ResultList } from '@digabi/ui-result-list'
import { T, useLanguage } from '../common/translate'
import { useAxiosGet } from '../hooks'
import { DownloadReportsButton } from './download-reports-button'
import moment from 'moment'
import { TabProps } from '../common/types'
import { LoadingSpinner } from '../common/loading-spinner'

export function ResultListContainer({ scopeId, roleType }: TabProps) {
  const [resultList, setResultList] = useState<ResultListGroup[]>()
  const [examinationPeriod, setExaminationPeriod] = useState<string>()
  const [resultListUpdated, setResultListUpdated] = useState<string>()
  const [get, loading] = useAxiosGet()
  const language = useLanguage()

  useEffect(() => {
    void (async () => {
      const response = await get<{ examinationPeriod: string }>('/results/examinationPeriodForResults')
      setExaminationPeriod(response?.examinationPeriod)
    })()
  }, [])

  useEffect(() => {
    if (!examinationPeriod) {
      return
    }

    void (async () => {
      const response = await get<ResultListApi>(
        uri`/school/result-list/${scopeId}/role/${roleType.toLowerCase()}/examination/${examinationPeriod}`,
        500
      )
      if (response?.results) {
        setResultList(response.results)
      }
      if (response?.created) {
        setResultListUpdated(response.created)
      }
    })()
  }, [scopeId, roleType, examinationPeriod])

  if (loading) {
    return <LoadingSpinner loading={loading} />
  }

  if (!resultList) {
    return (
      <span id="results-error">
        <T>results.error_while_loading_results</T>
      </span>
    )
  }

  if (!resultList.length) {
    return (
      <span id="results-not-published">
        <T>results.not_published</T>
      </span>
    )
  }

  return (
    <div id="exam-results-tab" className="tab">
      <div className="result-list-container">
        <div className="tools">
          <DownloadReportsButton examinationPeriod={examinationPeriod!} schoolUuid={scopeId} />
          <br />
          <span className="updated">
            <T>results.updated</T>: {moment(resultListUpdated).format('DD.MM.YYYY HH.MM')}
          </span>
        </div>
        <ResultList language={language} resultList={resultList} />
      </div>
    </div>
  )
}
