import React, { useEffect, useState } from 'react'
import i18next, { TOptions } from 'i18next'
import { Translation } from 'react-i18next'

export const translate = (key: string, params?: TOptions) => (params ? i18next.t(key, params) : i18next.t(key))

export const T = ({ children: key, params }: { children: string; params?: TOptions }) => (
  <Translation>{t => <>{t(key, params)}</>}</Translation>
)

export const useLanguage = () => {
  const [language, setLanguage] = useState(i18next.language)

  useEffect(() => {
    i18next.on('languageChanged', setLanguage)

    return () => {
      i18next.off('languageChanged', setLanguage)
    }
  }, [])

  return language as 'fi' | 'sv'
}
