import React from 'react'
import { Inspections } from './inspections'
import { FirstLevelCensoring } from './first-level-censoring'
import { NextLevelCensoring } from './next-level-censoring'
import { ProductiveCensoring } from './productive-censoring'
import { TabProps } from '../common/types'

export const Censoring = ({ scopeId }: TabProps) => (
  <div id="exam-grading-tab">
    <div id="held-exams">
      <Inspections divisionId={scopeId} />
      <FirstLevelCensoring divisionId={scopeId} />
      <NextLevelCensoring divisionId={scopeId} />
      <ProductiveCensoring divisionId={scopeId} />
    </div>
  </div>
)
