import React, { useEffect, useState } from 'react'
import { T, translate, useLanguage } from '../common/translate'
import * as utils from '../common/client-utils-commonjs'
import { TabProps } from '../common/types'
import { useAxiosGet } from '../hooks'
import { LoadingSpinner } from '../common/loading-spinner'

type Language = 'fi' | 'sv'

type ExamEventResponse = {
  eventDate: string
  titles: {
    examEventTitleFi: string
    examEventTitleSv: string
  }
  multimebFilename: string
  exams: Exam[]
  hasRegistrations: boolean
}

type ExamEvent = ExamEventResponse & {
  eventDateStr: string
  title: string
  hasValidMeb: boolean
}

type Exam = {
  title: string
  examDate: string
  examRegCode: string
  isPaperExam: boolean
  nameFinnish: string
  nameSwedish: string
  hasRegistrations: boolean
}

export const ExamEvents = ({ scopeId, roleType }: TabProps) => {
  const [examEvents, setExamEvents] = useState<ExamEventResponse[]>([])
  const [examServerCode, setExamServerCode] = useState<string>('')

  const language = useLanguage() as Language
  const [get, loading] = useAxiosGet()

  useEffect(() => {
    void (async () => {
      const [examEvents, serverCode] = await Promise.all([
        get<ExamEventResponse[]>(`/exam-api/exams/exam-events/${scopeId}`),
        roleType === 'PRINCIPAL' ? get<string>(`/configuration/exam-server-code`) : Promise.resolve('')
      ])
      setExamEvents(examEvents ? examEvents : [])
      setExamServerCode(serverCode || '')
    })()
  }, [scopeId, roleType])

  const isAssistant = roleType === 'ASSISTANT'

  return (
    <div id="exam-events-tab" className="tab">
      <LoadingSpinner loading={loading} />
      {!isAssistant && <ExamServerCode examServerCode={examServerCode} language={language} />}
      <div className="digital-exams-publication-notification">
        <T>sa.digital_exams_publication_info</T>
      </div>
      <table id="exam-events">
        <thead>
          <tr>
            <th className="event-date-col">
              <T>sa.exam_date</T>
            </th>
            <th className="event-exams-col">
              <T>sa.exams_of_event</T>
            </th>
            <th className="event-files-col">
              <T>sa.files</T>
            </th>
          </tr>
        </thead>
        {formatExamEvents(examEvents, language).map((examEvent, index) => (
          <ExamEvent key={index} {...examEvent} schoolId={scopeId} isAssistant={isAssistant} />
        ))}
      </table>
    </div>
  )
}

type ExamEventProps = ExamEvent & { schoolId: string; isAssistant: boolean }

const ExamEvent = (props: ExamEventProps) => {
  const { eventDateStr, hasRegistrations, exams } = props
  return (
    <tbody className="exam-event" data-event-date={eventDateStr}>
      <ExamEventHeader {...props} />
      {!hasRegistrations && <PaperExams />}
      {exams && <Exams {...props} />}
    </tbody>
  )
}

const Exams = ({ exams, hasValidMeb, isAssistant, multimebFilename }: ExamEventProps) => (
  <tr className="exam-event exams-row">
    <td></td>
    <td className="exams-list">
      <ul className="exams-of-event">
        {exams.map(({ title }, index) => (
          <li key={index} className="exam">
            <div className="name">{title}</div>
          </li>
        ))}
        {isAssistant && (
          <div className="no-meb-download-rights">
            <T>sa.no_access_rights_for_meb_download</T>
          </div>
        )}
      </ul>
    </td>
    <td className="download-exam">
      {!isAssistant && hasValidMeb && (
        <a href={`/exam-api/exams/exam-event/mex/${multimebFilename}`}>
          <button className="download-exam-button" type="button">
            <T>sa.download_exam</T>
          </button>
        </a>
      )}
    </td>
  </tr>
)

const ExamEventHeader = ({ eventDateStr, eventDate, title, hasRegistrations, schoolId }: ExamEventProps) => (
  <tr className="exam-event-header-row">
    <th>{eventDateStr}</th>
    <th className="js-exam-event-title">{title}</th>
    <th className="download-minutes">
      {hasRegistrations && (
        <a href={`/exam-api/exams/exam-event/minutes/${schoolId}/${eventDate}`}>
          <button className="download-minutes-button" type="button">
            <T>sa.download_minutes</T>
          </button>
        </a>
      )}
    </th>
  </tr>
)

const PaperExams = () => (
  <tr className="exam-event paper-exams-row">
    <td></td>
    <td className="exams-list">
      <span className="no-paper-exam-registrations">
        <T>sa.no_registrations_to_exam_event</T>
      </span>
    </td>
    <td className="download-grading-form"></td>
  </tr>
)

const ExamServerCode = ({ examServerCode, language }: { examServerCode: string; language: Language }) => (
  <div className="exam-server-code-container">
    <div>
      <T>sa.exam_server_code_instructions</T>
    </div>
    <p id="exam-server-code">
      {examServerCode && (
        <>
          <T>sa.exam_server_code</T>: <i>{examServerCode}</i>
        </>
      )}
    </p>
    <a href={translate('sa.exam_server_help_link_url')} target="_blank" rel="noreferrer">
      <T>sa.exam_server_help_link</T>
    </a>
  </div>
)

function formatExamEvents(examEvents: ExamEventResponse[], lang: Language) {
  return examEvents.map<ExamEvent>(examEvent => ({
    ...examEvent,
    eventDateStr: toFinnishDate(examEvent.eventDate),
    exams: examTitleByLanguageForExams(examEvent.exams, lang),
    title: eventTitleByLanguage(examEvent, lang),
    hasValidMeb: examEvent.multimebFilename !== null
  }))
}

function toFinnishDate(YYYY_MM_DD: string) {
  return utils.yyyyMmDdStringToFinnishDateString(YYYY_MM_DD)
}

function examTitleByLanguageForExams(exams: Exam[], lang: Language) {
  return exams
    .map(exam => ({
      ...exam,
      title: examTitleByLanguage(exam, lang)
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1))
}

function examTitleByLanguage(exam: Exam, lang: Language) {
  const languageMapping = { fi: 'nameFinnish', sv: 'nameSwedish' }
  return exam[languageMapping[lang] as keyof { nameFinnish: string; nameSwedish: string }]
}

function eventTitleByLanguage(event: ExamEventResponse, lang: Language) {
  const languageMapping = { fi: 'examEventTitleFi', sv: 'examEventTitleSv' }
  return Object.keys(event.titles)
    ? event.titles[languageMapping[lang] as keyof { examEventTitleFi: string; examEventTitleSv: string }]
    : ''
}
