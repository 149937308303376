import React from 'react'
import { client } from '../registration/registration'
import { T } from '../common/translate'

interface DownloadReportsButtonProps {
  examinationPeriod: string
  schoolUuid: string
}

interface ReportFileDetails {
  fileName: string
  fileType: string
  label: { fi: string; sv: string }
}

export function DownloadReportsButton({ examinationPeriod, schoolUuid }: DownloadReportsButtonProps) {
  const downloadResultListFiles = async () => {
    const response = await client.get<ReportFileDetails[]>(`/results/report-files/${examinationPeriod}`)
    await Promise.all([
      ...(response.data ? createReportFileDownloadRequests(response.data) : []),
      downloadFile(`/results/${examinationPeriod}/${schoolUuid}/exam-specific-results`)
    ])
  }

  const createReportFileDownloadRequests = (reportFiles: ReportFileDetails[]) =>
    reportFiles.map(async reportFile => {
      const response = await client.get<{ exists: boolean }>(
        `/results/${examinationPeriod}/${schoolUuid}/report/${reportFile.fileName}/${reportFile.fileType}/exists`
      )

      if (response.data.exists) {
        return downloadFile(
          `/results/${examinationPeriod}/${schoolUuid}/report/${reportFile.fileName}/${reportFile.fileType}`
        )
      }
      return Promise.resolve()
    })

  const downloadFile = async (url: string) => {
    const response = await client.get(url, { method: 'GET', responseType: 'blob' })
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))

    const linkElement = document.createElement('a')
    const filename = (response.headers as { 'content-disposition': string })['content-disposition'].split(
      'filename='
    )[1]

    linkElement.href = downloadUrl
    linkElement.setAttribute('download', filename)

    document.body.appendChild(linkElement)
    linkElement.click()
    linkElement.remove()
  }

  return examinationPeriod ? (
    <a onClick={() => void downloadResultListFiles()}>
      <button className="js-download-results-list-link button" type="button">
        <T>results.download_files</T>
      </button>
    </a>
  ) : null
}
