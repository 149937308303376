import React from 'react'
import { T } from '../common/translate'

interface PaymentInformationProps {
  paymentInfo: {
    referenceCode: string
    examinationPeriodSeasonIsAutumn: boolean | null
    examinationPeriodYear: string
    studentFeeTotalForSchool: string
  }
}

export function PaymentInformation(props: PaymentInformationProps) {
  const { referenceCode, examinationPeriodSeasonIsAutumn, examinationPeriodYear, studentFeeTotalForSchool } =
    props.paymentInfo
  return (
    <>
      <h3>
        <T>registration.payment_info</T>
      </h3>

      <table className="js-invoice-table basic-table narrow-table">
        <tbody>
          <tr>
            <th>
              <T>registration.bank_account_number</T>
            </th>
            <td>
              <dl>
                <dt>Danske Bank</dt>
                <dd>FI51 8129 9710 0112 97</dd>
                <dt>Nordea</dt>
                <dd>FI65 1804 3000 0156 85</dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th>
              <T>registration.reference_number</T>
            </th>
            <td>{referenceCode}</td>
          </tr>
          <tr>
            <th>
              <T>registration.due_date</T>
            </th>
            <td>{examinationPeriodSeasonIsAutumn ? `5.9.${examinationPeriodYear}` : `5.3.${examinationPeriodYear}`}</td>
          </tr>
          <tr>
            <th>
              <T>registration.student_fee_in_total</T>
            </th>
            <td>{studentFeeTotalForSchool}&nbsp;€</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
