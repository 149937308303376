import { Spinner } from 'spin.js'

export const ui = {
  spinnerYtlVerySmallOpts: {
    lines: 9, // The number of lines to draw
    length: 4, // The length of each line
    width: 1.5, // The line thickness
    radius: 3, // The radius of the inner circle
    corners: 1.2, // Corner roundness (0..1)
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#000', // #rgb or #rrggbb or array of colors
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    shadow: false, // Whether to render a shadow
    hwaccel: true, // Whether to use hardware acceleration
    className: 'spinner', // The CSS class to assign to the spinner
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    top: '50%', // Top position relative to parent
    left: '50%' // Left position relative to parent
  },
  spinnerYtlSmallOpts: {
    lines: 10, // The number of lines to draw
    length: 6, // The length of each line
    width: 2.5, // The line thickness
    radius: 5, // The radius of the inner circle
    corners: 1.0, // Corner roundness (0..1)
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#000', // #rgb or #rrggbb or array of colors
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    shadow: false, // Whether to render a shadow
    hwaccel: true, // Whether to use hardware acceleration
    className: 'spinner', // The CSS class to assign to the spinner
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    top: '50%', // Top position relative to parent
    left: '50%' // Left position relative to parent
  },
  spinner: null,
  startSpinner: function (el, spinnerStyle) {
    el.show()
    if (ui && ui.spinner) {
      ui.spinner = new Spinner(spinnerStyle || ui.spinnerYtlSmallOpts).spin()
      ui.spinner && el.append(ui.spinner.el)
    }
  },
  stopSpinner: function (el) {
    el.hide()
    ui?.spinner?.stop()
    ui.spinner = null
  }
}
