import React from 'react'
import { T } from './common/translate'
import i18next from 'i18next'

export const LoginPage = () => (
  <>
    <div className="login-notification" data-i18n="sa.yo.service-break-20180201-20180206"></div>
    <div id="vetuma-login">
      <h1>
        <T>sa.yo.login-header</T>
      </h1>
      <p>
        <T>sa.yo.login-info</T>
      </p>
      <h3>
        <T>sa.yo.login-method-header</T>
      </h3>
      <ul className="login-method-list">
        <li>
          <T>sa.yo.login-method-bank</T>
        </li>
        <li>
          <T>sa.yo.login-method-mobile</T>
        </li>
        <li>
          <T>sa.yo.login-method-id-card</T>
        </li>
      </ul>
      <div className="login-error">
        <T>sa.yo.login-error</T>
      </div>
      <button
        className="login"
        onClick={() => window.location.assign(`/kurko-api/user/login/vetuma?lang=${i18next.language}`)}>
        <T>sa.yo.login-button</T>
      </button>
    </div>
  </>
)
