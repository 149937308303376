import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './app'
import * as i18n from './common/i18n'
import './common/expose-jquery-global'

import '../../less/sa-yo.less'

i18n.init(() => {
  const container = document.getElementById('main-sayo')
  const root = createRoot(container)
  root.render(<App />)
})
