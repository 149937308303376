import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../common/types'

type Credentials = {
  schoolId: string
  apiKey: string
}

type ApiKeyDisplayProps = {
  keyValue: string
  isCopied: boolean
  version: 1 | 2
  onCopy: (version: number) => void
}

export function Credentials({ scopeId, roleType }: TabProps) {
  const { t } = useTranslation()
  const [abitti1Key, setAbitti1Key] = useState<string>('')
  const [abitti2Key, setAbitti2Key] = useState<string>('')
  const [key1Copied, setKey1Copied] = useState(false)
  const [key2Copied, setKey2Copied] = useState(false)

  useEffect(() => {
    if (roleType === 'PRINCIPAL') {
      void (async () => {
        const response = await fetch(`/configuration/exam-server-code`)
        if (response.ok) {
          const abitti1Code = (await response.json()) as string
          setAbitti1Key(abitti1Code)
        }
      })()
    }
  }, [roleType])

  useEffect(() => {
    void (async () => {
      const response = await fetch(`/kurko-api/api-key/${scopeId}`)
      if (response.ok) {
        const { apiKey } = (await response.json()) as Credentials
        setAbitti2Key(apiKey)
      }
    })()
  }, [])

  function copyCodeToClipboard(abittiVersion: number) {
    if (abittiVersion === 1) {
      navigator.clipboard.writeText(abitti1Key).catch(console.error)
      setKey1Copied(true)
    } else {
      navigator.clipboard.writeText(abitti2Key).catch(console.error)
      setKey2Copied(true)
    }
    setTimeout(() => {
      setKey1Copied(false)
      setKey2Copied(false)
    }, 3000)
  }

  return (
    <div id="credentials-tab" className="tab">
      <div>
        <ApiKeyDisplay keyValue={abitti1Key} isCopied={key1Copied} version={1} onCopy={copyCodeToClipboard} />
        <p>
          <a href={t('credentials.exam_server_help_link_url')} target="_blank" rel="noreferrer">
            {t('credentials.exam_server_help_link')}
          </a>
        </p>
      </div>

      <div>
        <ApiKeyDisplay keyValue={abitti2Key} isCopied={key2Copied} version={2} onCopy={copyCodeToClipboard} />
      </div>
    </div>
  )
}

function ApiKeyDisplay({ keyValue, isCopied, version, onCopy }: ApiKeyDisplayProps) {
  const { t } = useTranslation()

  return (
    <>
      <h2>{t(`credentials.title_for_abitti${version}`)}</h2>
      {!keyValue ? (
        <p className="missing">
          {t(version === 1 ? 'credentials.abitti1_key_not_published' : 'credentials.abitti2_key_missing')}
        </p>
      ) : (
        <div className="api-key-container">
          <input type="text" value={keyValue} className="api-key" readOnly={true} size={keyValue.length + 1} />
          <button className="copy-api-key-btn" onClick={() => onCopy(version)}>
            {t('credentials.copy')}
          </button>
        </div>
      )}
      {isCopied && <span className="copied">{t('credentials.copied')}</span>}
      <div>{t(`credentials.instructions_for_abitti${version}`)}</div>
    </>
  )
}
